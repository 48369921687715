body {
  height: 90vh;
  width: 99vw;
  overflow-x: hidden;
}

.App {
  height: 100vh;
  width: 99vw;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #36454F;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #36454F;
  padding: 1%;
  font-size: 12px;
  width: 99%;
  color: white;
  text-align: center;
  margin-top: auto;
}

.coming-soon {
  text-align: center;
  background-color: #36454F;
  color: white;
  padding: .5%;
  padding-top: 1%;
  padding-bottom: 1%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.fledge-container {
  width: 97%;
}

.main-container {
  width: 97%;
}

.main-container p{
  margin-bottom: .1%;
  font-size: 17px;
  margin-top: .5%;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
}

.intro-zero {
  padding: .5% 1%;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
}

.intro-zero p{
  margin-bottom: .1%;
  margin-top: .3%;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
}

.App-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-navigation {
  width: 98vw;
  margin-top: .5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: center;
  border-radius: .5%;
  border:.1px thin #ccc;
}

.App-engineering-navigation {
  width: 98vw;
  margin-top: .5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: center;
  border-radius: .5%;
  border:.1px thin #ccc;
}

.App-nav-text {
  font-size: large;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
  width: 25%;
  margin-right: .2%;
  background-color: #ececef;
  text-align: center;
  align-content: center;
}

.App-nav-text-active {
  font-size: large;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
  width: 25%;
  background-color: white;
  text-align: center;
  align-content: center;
}

.App-nav-text-engineering {
  font-size: medium;
  font-weight: 400;
  width: 15%;
  font-family: 'Times New Roman', Times, serif;
  margin-right: .2%;
  background-color: #ececef;
  padding: 1%;
  text-align: center;
  align-content: center;
}

.App-nav-text-engineering-active {
  font-size: medium;
  font-weight: 400;
  width: 15%;
  font-family: 'Times New Roman', Times, serif;
  padding: 1%;
  background-color: white;
  text-align: center;
  align-content: center;
}

.App-nav-text:hover, .App-nav-text-engineering:hover {
  cursor: pointer;
  text-decoration-style: solid;
  padding: 1%;
}

.App-link {
  color: #61dafb;
}

.membership {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.membership-header {
  color: #363f4f;
  text-align: center;
}

.membership-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center; /* Centers vertically */
  align-items: center; /* Centers horizontally */
}

.memcard-availability {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-top: auto;
  text-align: center;
}

.mmmmmm {
  margin: 0 auto;
  width: 100%;
}

.header-holder {
  background-color: #363f4f;
  color: white;
}

.x-membership {
  background-color: #363f4f;
  color: white;
  padding: 1%;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  margin-inline: 1%;
  width: fit-content;
  height: 615px;
}

.x-membership h2{
  margin-left: 2%;
  margin-bottom: 0;
}

.x-membership h3{
  margin-left: 2%;
  margin-bottom: 0;
}

.headerHolder p {
  margin-left: 2%;
  margin-top: 0;
}

.x-membership li{
  margin-bottom: .1%;
  margin-top: .5%;
  font-weight: 400;
  font-size: 17px;
  font-family: 'Times New Roman', Times, serif;
}

.memcard-footer {
  margin-top: auto;
  text-align: center;
}

.memcard-get-started {
  margin-top: auto;
  text-align: center;
}

.memcard-get-started:hover {
  cursor: pointer;
}

.memcard-get-started h4 {
  padding: 2%;
  margin: auto;
  background-color: whitesmoke;
  color: black;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.commitment-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 3%;
}

.commitment-header {
  text-align: left;
  margin: 1%
}

.commitment-lister {
  list-style: none;
  width: 100%;
  text-align: left;
  margin: 12px -12px;
}

.commitment-container p {
  margin: 0;
  padding: 0;
  margin-left: 3%;
  padding: .5%;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
}

.project-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 520px;
  margin: 16px;
}

.project-card p {
  margin: 8px 0;
}

.project-card a {
  color: #007bff;
  text-decoration: none;
}

.project-card a:hover {
  text-decoration: underline;
}

.sub-item-flex {
  flex: 1 1 50%;
}

.in-scope {
  padding-top: 0.9%;
}

.contact-form {
  border: 1px light #36454F;
  padding: 20px;
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input, .contact-form select {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #36454F;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.contact-nav {
  padding: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-form-return {
  margin-right: 3%;
}

.contact-form-return p{
  background-color: #363f4f;
  color: white;
  height: fit-content;
  padding-inline: 23%;
  padding-top: 10%;
  padding-bottom: 10%;
  width: fit-content;
  border-radius: 8px;
}

.contact-form-return:hover {
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .x-membership {
    margin-bottom: 5%;
    width: 350px;
    height: 650px;
    padding-bottom: 3%;
  }
}

@media (max-width: 500px) {
  .membership-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .membership {
    width: 100%;
  }
  
  .x-membership {
    margin-bottom: 5%;
    width: 350px;
    height: fit-content;
    padding-bottom: 3%;
  }

  .App-container {
   padding-inline: .5%; 
  }

  .App-navigation {
    width: 100%;
    margin-top: .5%;
    display: flex;
    flex-wrap: wrap;    
    align-items: center;
    background-color: white;
    justify-content: center;
    padding-bottom: 2%;
  }

  .App-nav-text {
    font-size: medium;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
    width: 25%;
    height: fit-content;
    margin-right: 1%;
    margin-bottom: 2%;
    background-color: #ececef;
    padding: 2%;
    flex: 1 1 40%;
    text-align: center;
    align-content: center;
  }
  
  .App-nav-text-active {
    font-size: medium;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
    width: 25%;
    height: fit-content;
    padding: 2%;
    margin-right: 1%;
    margin-bottom: 2%;
    background-color: white;
    flex: 1 1 40%;
    text-align: center;
    align-content: center;
  }

  .App-nav-text:hover {
    cursor: pointer;
    text-decoration-style: unset;
    padding: 1%;
  }

  .contact-form {
    border: 1px light #36454F;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .contact-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 15px;
    user-select: none; /* Disable default text selection */
  }

  .contact-form select {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    height: 35px;
  }

  .contact-nav h2 {
    margin-left: 6%;
  }

  .contact-form-return {
    margin-right: 6%;
  }
  
  .contact-form option {
    color: #363f4f;
  }

  .sub-item-flex {
    flex: unset;
  }

  .intro-zero p{
    font-size: 17px;
    margin-bottom: .8%;
    margin-top: .9%;
  } 
  
  .in-scope {
    padding-top: 2.9%;
  }

  .contact-form .hs-note {
    font-size: 16px;
    height: fit-content;
    word-wrap: break-word;
  }

  .contact-form .hs-note::placeholder {
    font-size: 12px;
    margin-top: -30px;
  }

  .App-nav-text-engineering {
    font-size: medium;
    font-weight: 400;
    width: 25%;
    font-family: 'Times New Roman', Times, serif;
    margin: 1%;
    background-color: #ececef;
    padding: 3%;
    text-align: center;
    align-content: center;
  }
  
  .App-nav-text-engineering-active {
    font-size: medium;
    font-weight: 400;
    width: 25%;
    font-family: 'Times New Roman', Times, serif;
    padding: 3%;
    background-color: white;
    text-align: center;
    align-content: center;
  }

  .App-engineering-navigation {
    width: 98vw;
    margin-top: .5%;
    display: flex;
    flex-direction: row;
    align-items: unset;
    background-color: white;
    justify-content: unset;
    border-radius: .5%;
    border:.1px thin #ccc;
  }

  .mmmmmm {
    margin: 0 auto;
  }

  .memcard-footer {
    margin-bottom: 2%;
  }
  
}